import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LanguagesService } from './services/languages.service';

import { iosTransitionAnimation } from '@ionic/core/dist/collection/utils/transition/ios.transition.js';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, 
    HttpClientModule,
    IonicModule.forRoot({ mode: 'md', backButtonIcon: 'arrow-back-circle-outline', navAnimation: iosTransitionAnimation }), 
    AppRoutingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    LanguagesService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_INITIALIZER, useFactory: (langs: LanguagesService) => () => langs.initLang("it"), deps: [LanguagesService], multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
