import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LanguagesService {
  private langObservable :Subject<void> = new Subject();
  private lbslangs;
  public currentLang :Observable<void> = this.langObservable.asObservable();
  public lg :string;

  constructor(private http: HttpClient, private navCtrl :NavController) { }
  
  initLang(lgchoose :string) {
		return new Promise((resolve, reject) => {
			this.http.get('assets/langs/'+lgchoose+'.json').subscribe(
				result => { 
					this.lg = lgchoose;
					this.lbslangs = result;
					resolve(true);
				},
				error => {
					console.log('Language file "'+lgchoose+'" could not be read');
					resolve(true);
				}
			);
    });
  }

  choiseLgUrl() {
	this.navCtrl.navigateRoot('/intro');
  }
  
  getLgSel() :string {
		return this.lg;
  }
  
  getLabels() {
		return this.lbslangs;
  }
  
  label(lbchoose :string) :string {
		if(this.lbslangs[lbchoose] !== undefined)
			return this.lbslangs[lbchoose];
		else
			return "Label not found";
  }
}
